// This file should only contain critical code for first load. Most code
// should live in index.ts which is loaded with `defer` set.
import lazySizes from "lazysizes"

// Set lazySizes horizontal loading factor to 1.1 to preload images
// not yet shown in the scroll slider.
lazySizes.cfg.expFactor = 3

// Mark the page as having JS
document.documentElement.classList.add("js")
